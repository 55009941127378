import { useEffect, useState } from "react";
import BundleGroupTable from "@/components/admin/BundleGroupTable";
import { getBundleGroupList } from "@/utils/admin/fetch";
import { handleLogout } from "@/utils/admin/logout";
import { Link } from "react-router-dom";
import { LOCALES } from "@/const";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const BundleGroupList = () => {
  const { t } = useTranslation("translation");

  const [bundleGroups, setBundleGroup] = useState([]);
  const [loading, setLoading] = useState(true);
  const [locale, setLocale] = useState("");
  const [genericNameFilter, setGenericNameFilter] = useState("");
  const [liveFilter, setLiveFilter] = useState("all");
  const [statusFilter, setStatusFilter] = useState("all");

  const handleLocaleChange = ({ target }) => {
    const value = target.value === "All" ? "" : target.value;
    setLocale(value);
  };

  const handleResetFilters = () => {
    setGenericNameFilter("");
    setLocale("");
    setLiveFilter("all");
    setStatusFilter("all")
  }

  const handleGenericNameChange = ({ target }) => {
    const newValue = target.value
    setLoading(true);
    setTimeout(function () {
      setGenericNameFilter(newValue);
      setLoading(false);
    }, 250);
  }

  const handleIsLiveChange = ({ target }) => {
    const newValue = target.value
    setLoading(true);
    setTimeout(function () {
      setLiveFilter(newValue);
      setLoading(false);
    }, 250);
  }

  const handleStatusChange = ({ target }) => {
    const newValue = target.value
    setLoading(true);
    setTimeout(function () {
      setStatusFilter(newValue);
      setLoading(false);
    }, 250);
  }

  useEffect(() => {
    getBundleGroupList({ view_id: locale }).then((res) => {
      if (res.status !== 401) {
        setLoading(true);
        setTimeout(function () {
          setBundleGroup(Array.isArray(res) ? res : []);
          setLoading(false);
        }, 250);
      } else handleLogout();
    });
  }, [locale]);

  const genericNames = bundleGroups.reduce((acc, bundleGroup) => {
    if (!acc.includes(bundleGroup.generic_name)) acc.push(bundleGroup.generic_name)
    return acc
  }, []);


  const getFilteredBundleGroupList = () => {
    return bundleGroups.filter((bundleGroup) => {
      if (genericNameFilter && bundleGroup.generic_name !== genericNameFilter) return false
      if (liveFilter !== 'all' && bundleGroup.is_live !== parseInt(liveFilter)) return false
      if (statusFilter !== 'all' && bundleGroup.published !== parseInt(statusFilter)) return false
      return true
    });
  }

  const bundleGroupList = getFilteredBundleGroupList()

  return (
    <div className="page-content">
      <div className="container mx-auto">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              {t("bundle-group-list.title")}
            </h1>
            <p className="mt-2 text-sm text-gray-700">{t("bundle-group-list.intro")}</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex gap-1">
            <select
              required
              id="bundleGroupIsLive"
              name="bundleGroupIsLive"
              className={`mt-1 block w-full text-base border-gray-300 focus:outline-none focus:ring-black focus:border-black sm:text-sm p-3 w-auto pr-9`}
              onChange={handleIsLiveChange}
              value={liveFilter}
            >
              <option value={'all'}>{t('bundle-list.filters.is-live')}</option>
              <option value={1}>Live</option>
              <option value={0}>Not Live</option>
            </select>
            <select
              required
              id="bundleGroupStatus"
              name="bundleGroupStatus"
              className={`mt-1 block w-full text-base border-gray-300 focus:outline-none focus:ring-black focus:border-black sm:text-sm p-3 w-auto pr-9`}
              onChange={handleStatusChange}
              value={statusFilter}
            >
              <option value={'all'}>{t('bundle-list.filters.all-status')}</option>
              <option value={1}>Published</option>
              <option value={0}>Draft</option>
            </select>
            <select
              required
              id="bundleGroupLocale"
              name="bundleGroupLocale"
              className={`mt-1 block w-full text-base border-gray-300 focus:outline-none focus:ring-black focus:border-black sm:text-sm p-3 w-auto pr-9`}
              value={locale === "" ? "All" : locale}
              onChange={handleLocaleChange}
            >
              <option hidden value=""></option>
              <option key={-1} value={"All"}>
                {t('bundle-group-list.filters.all-locales')}
              </option>
              {LOCALES.map((locale, index) => (
                <option key={index} value={locale}>
                  {locale}
                </option>
              ))}
            </select>
            <select
              required
              id="bundleGroupGenericName"
              name="bundleGroupGenericName"
              className={`mt-1 block w-full text-base border-gray-300 focus:outline-none focus:ring-black focus:border-black sm:text-sm p-3 w-auto pr-9`}
              onChange={handleGenericNameChange}
              value={genericNameFilter}
            >
              <option value="">{t('bundle-group-list.filters.all-bundle-group')}</option>
              {genericNames.map((genericName, index) => <option key={index} value={genericName}>{genericName}</option>)}
            </select>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link to="/bundle-group/new">
              <button
                className="inline-flex items-center justify-center border border-transparent bg-black p-3 text-sm font-medium text-white shadow-sm hover:opacity-75 duration-200 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 sm:w-auto"
                type="button"
              >
                {t("table.add-bundle-group")}
              </button>
            </Link>
          </div>
        </div>
        <BundleGroupTable bundleGroups={bundleGroupList} loading={loading} />
        {!bundleGroupList.length && <div className="w-full flex flex-col mt-9 justify-center items-center">
          <button className="inline-flex justify-center p-3 border border-transparent shadow-sm text-sm font-medium text-white bg-black hover:opacity-50 duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black relative" onClick={handleResetFilters}>
            {t('bundle-group-list.filters.reset-filters')}
          </button>
        </div>}
      </div>
    </div>
  );
};

export default BundleGroupList;
