import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    createNewCollection,
    getCrossLocaleCollectionInputs,
    updateCrossLocaleCollection
} from "@/utils/admin/fetch";
import Error404 from "@/pages/404";

import { LOCALES } from "@/const";
import Alert from "@/components/admin/Alert";
import { validateCollectionInputs, getUpdatedAtTimeString } from "@/utils/admin/form";
import { handleLogout } from "@/utils/admin/logout";
import { validateToken } from "@/utils/admin/fetch";

import * as Tooltip from '@radix-ui/react-tooltip';
import '../../../styles/tooltip.css';
import { ReactComponent as QuestionMarkIcon } from '@/assets/svg/questionMark.svg'

import { useTranslation } from "react-i18next";
import "../../../i18n";

const CollectionForm = (props) => {
    const [inputs, setInputs] = useState({})
    const { key } = useParams()
    const [id, view_id] = key?.split("-") || []
    const [translations, setTranslations] = useState({});

    const [alert, setAlert] = useState(null)
    const [popupContent, setPopupContent] = useState(null)

    const [notFound, setNotFound] = useState(false)
    const [translationPopUpShown, setTranslationPopUpShown] = useState(false)
    const [waitingResponse, setWaitingResponse] = useState(false)

    const { t } = useTranslation("translation");

    useEffect(() => {
        validateToken()
            .then(res => {
                if (!res) handleLogout()
            })
    }, [])

    useEffect(() => {
        if (props.edit || props.duplicate) {
            const getCollectionInputsThen = (res) => {
                res ? setInputs({...res}) : setNotFound(true)
            }
            if (props.multiLocale) {
                getCrossLocaleCollectionInputs(id).then(getCollectionInputsThen)
            }
        }
    }, [id, props.duplicate, props.edit, props.multiLocale, view_id])

    useEffect(() => {
        if (props.create) {
            setInputs((prevInputs) => ({
                ...prevInputs
            }))
        }
    }, [props.create])

    const handleChange = ({ target }) => {
        const name = target.name;
        if (target.type === "checkbox") {
            const checked = target.checked;
            setInputs((values) => ({ ...values, [name]: checked }));
        } else {
            const value = target.value;
            setInputs((values) => ({ ...values, [name]: value }));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (validateCollectionInputs(inputs)) {
            setWaitingResponse(true);

            if (props.edit) {
                const updateCollectionThen = (res) => {
                    if (res.status !== 401) {
                        setWaitingResponse(false);
                        handleAlert(res);
                    } else handleLogout();
                }

                updateCrossLocaleCollection({ ...inputs, id, view_id }).then(updateCollectionThen);
            }
            if (props.create || props.duplicate) {
                createNewCollection(inputs).then((res) => { 
                    if (res.status !== 401) {
                        setWaitingResponse(false);
                        handleAlert(res);
                    } else handleLogout();
                });
            }
        } else {
            setAlert({
                shown: true,
                error: true,
                title: t("form.input-not-valid"),
                actions: {
                    primary: {
                        callback: () => setAlert({ ...alert, shown: false }),
                        title: t("table.alert.close"),
                    },
                },
            });
        }
    };

    const handleAlert = (res) => {
        if (res.status !== 200)
            setAlert({
                shown: true,
                error: true,
                message: res.message,
                title: `${t("form.saving-error")} (${res.status})`,
                actions: {
                    primary: {
                        callback: () => setAlert({ ...alert, shown: false }),
                        title: t("table.alert.close"),
                    },
                },
            });
        else
            setAlert({
                shown: true,
                error: false,
                title: t("form.update-saved"),
                message: t("form.update-collection-saved-detail"),
                actions: {
                    primary: {
                        callback: () => (window.location.href = "/"),
                        title: t("form.back-to-list"),
                    },
                },
            });
    };

    const getCTAText = () => {
        if (props.edit) return t("form.edit");
        if (props.duplicate) return t("form.create-duplicate");
        else return t("form.create");
    };

    const getCTATextClass = () => {
        return waitingResponse ? "invisible" : "";
    };

    const toggleSelectAll = event => {
        event.preventDefault();
        if (inputs.locales?.length !== LOCALES.length)
            setInputs({ ...inputs, locales: LOCALES })
        else {
            setInputs({ ...inputs, locales: [] })
        }
    }

    const handleLocaleSelection = event => {
        if (inputs.locales?.length) {
            if (inputs.locales?.includes(event.target.value)) {
                setInputs({ ...inputs, locales: inputs.locales?.filter(l => l !== event.target.value) })
            }
            else {
                setInputs({ ...inputs, locales: [...inputs?.locales, event.target.value] })
            }
        } else setInputs({ ...inputs, locales: [event.target.value] })

    }

    const openTranslationPopUp = (event, contentType) => {
        event.preventDefault()
        const newTranslations = Object.keys(inputs).reduce((acc, key) => {
            if (key.includes(`${contentType}-`)) {
                acc[key] = inputs[key]
            }
            return acc
        }, {})
        setPopupContent(contentType)
        setTranslations(newTranslations)
        setTranslationPopUpShown(true)
    }

    const collectionType = t("form.cross-locale-collection")

    return !notFound ? (
        <div className="page-content">
            <Alert
                title={alert?.title}
                message={alert?.message}
                error={alert?.error}
                shown={alert?.shown}
                actions={alert?.actions}
            />

            <form onSubmit={handleSubmit} className="container mx-auto">
                {(props.multiLocale && translationPopUpShown) &&
                    <TranslationPopUp
                        locales={inputs.locales}
                        translations={translations}
                        saveTranslations={(data) => {
                            setInputs(prevInputs => ({ ...prevInputs, ...data }))
                            setTranslationPopUpShown(false)
                        }}
                        closePopUp={() => setTranslationPopUpShown(false)}
                        contentType={popupContent}
                    />
                }

                <div className="divide-y divide-gray-200">
                    <div>
                        <div className="mb-12">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {getCTAText()} {t("form.collection")}
                            </h3>
                            <p className="mt-1 max-w-2.5xl text-sm text-gray-500">
                                {props.create ? t("form.create-collection-text") : t("form.cross-locale-update-text")}
                                <b className="block mt-2">{t("form.collection-important-note")}</b>
                            </p>
                            <p className="mt-2.5 italic max-w-2.5xl text-sm text-gray-500">
                                {t("form.mandatory-information")}
                            </p>
                        </div>

                        <div className="mb-5">
                            <h3 className="text-lg leading-6 font-medium text-gray-900 mt-5">
                                {collectionType} {t("form.details")}
                            </h3>
                            <p className="mt-1 max-w-2.5xl text-sm text-gray-500">
                                {t("form.info-text")}
                            </p>
                        </div>

                        <div className="group bg-gray-100 px-8">
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-5">
                                <label
                                    htmlFor="collectionLocale"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.locale-collection")} <span className="text-lg">*</span>
                                </label>
                                <div className={`mt-1 sm:mt-0 sm:col-span-2 flex flex-wrap gap-5${props.edit && !props.multiLocale ? ' opacity-50 pointer-events-none' : ''}`}>
                                    {LOCALES.map((locale, index) => (
                                        <div key={index} className="flex grow-0 items-center text-sm text-gray-700">
                                            <input id={`cb-${locale}`}
                                                type="checkbox"
                                                value={locale}
                                                onChange={handleLocaleSelection}
                                                checked={inputs.locales?.includes(locale) || false}
                                                className="w-4 h-4 text-black bg-white border-gray-300 focus:ring-black dark:focus:ring-black focus:ring-2" />
                                            <label htmlFor={`cb-${locale}`} className="ml-2 text-sm font-medium">{locale}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="flex pb-5">
                                <button className="text-sm font-semibold p-2 bg-gray-200" onClick={toggleSelectAll}>{inputs.locales?.length === LOCALES.length ? t("form.deselect-all") : t("form.select-all")}</button>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label
                                    htmlFor="collectionId"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.collection-id")} <span className="text-lg">*</span> <small>(Backend)</small>
                                    <Tooltip.Provider>
                                        <Tooltip.Root>
                                            <Tooltip.Trigger asChild>
                                                <button className="IconButton bg-gray-500 flex" style={{ flex: "0 0 25px" }} onClick={event => event.preventDefault()}>
                                                    <QuestionMarkIcon />
                                                </button>
                                            </Tooltip.Trigger>
                                            <Tooltip.Portal>
                                                <Tooltip.Content className="TooltipContent" sideOffset={5} side="bottom">
                                                    <div className="text-xs" dangerouslySetInnerHTML={{ __html: t("form.collection-name-tooltip") }}></div>
                                                    <Tooltip.Arrow className="TooltipArrow" />
                                                </Tooltip.Content>
                                            </Tooltip.Portal>
                                        </Tooltip.Root>
                                    </Tooltip.Provider>
                                </label>

                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        disabled={!props.multiLocale}
                                        required
                                        type="text"
                                        name="collectionId"
                                        id="collectionId"
                                        autoComplete="collectionId"
                                        placeholder={t("form.collection-name-placeholder")}
                                        value={inputs.collectionId || ""}
                                        onChange={handleChange}
                                        className={`flex-1 block w-full focus:ring-black focus:border-black min-w-0  sm:text-sm border-gray-300 p-3 ${!props.multiLocale && 'opacity-50'}`}
                                    />
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    {t('form.add-collection-name-translations')} <span className="text-lg">*</span>
                                </label>

                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <button
                                        className={`text-sm font-semibold mt-2 ${!inputs.locales?.length ? 'opacity-50' : 'hover:opacity-50 duration-200 cursor-pointer'}`}
                                        disabled={!inputs.locales?.length}
                                        onClick={(event) => openTranslationPopUp(event, 'name')}>
                                            + {t('form.add-collection-name-translations-cta')}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {props.edit ? (
                            <>
                                <div className="mb-5">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900 pt-14">
                                        {t("form.logs")}
                                    </h3>
                                    <p className="mt-1 max-w-2.5xl text-sm text-gray-500">
                                        {t("form.logs-collection-text")}
                                    </p>
                                </div>

                                <div className="group bg-gray-100 px-8">
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-5">
                                        <label
                                            htmlFor="collectionEndDate"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            {t("table.updated-by")}
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                required
                                                disabled
                                                type="text"
                                                value={inputs.collectionUpdatedBy || ""}
                                                onChange={handleChange}
                                                className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:max-w-xs sm:text-sm border-gray-300 opacity-50 p-3"
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                        <label
                                            htmlFor="collectionEndDate"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            {t("table.updated-at")}
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                required
                                                disabled
                                                type="text"
                                                value={getUpdatedAtTimeString(inputs.collectionUpdatedAt)}
                                                onChange={handleChange}
                                                className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:max-w-xs sm:text-sm border-gray-300 opacity-50 p-3"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>

                <div className="pt-5">
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="ml-3 inline-flex justify-center p-3 border border-transparent shadow-sm text-sm text-white bg-black hover:opacity-50 duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black relative">
                            {waitingResponse ? (
                                <div className="absolute animate-spin h-5 w-5 rounded-full border-2 border-white border-l-transparent" />
                            ) : null}
                            <span className={getCTATextClass()}>{getCTAText()} {collectionType}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    ) : (
        <Error404 />
    );
};

const TranslationPopUp = props => {
    const [translations, setTranslations] = useState({})
    const [maxLengthAlert, setMaxLengthAlert] = useState([])
    const { t } = useTranslation("translation")

    useEffect(() => {
        setTranslations(props.translations || {})
    }, [props.translations])

    const TranslationPopUpTitles = {
        name: t("form.add-collection-name-translations"),
    }

    const onChange = ({ target }) => {
        if (target.value.length === 50) {
            setMaxLengthAlert([...maxLengthAlert, target.name.replace(`${props.contentType}-`, "")])
        } else {
            const newMaxLengthLocales = maxLengthAlert.filter(locale => locale !== target.name.replace(`${props.contentType}-`, ""))
            setMaxLengthAlert(newMaxLengthLocales)
        }

        setTranslations({
            ...translations,
            [target.name]: target.value,
        })
    }

    const onSave = (event) => {
        event.preventDefault()
        const isAtLeastOneFilled = Object.values(translations).some(value => value.trim() !== "")

        if (!isAtLeastOneFilled) {
            alert(t("form.at-least-one-required"));
            return;
        }
        props.saveTranslations(translations)
        props.closePopUp()
    }
    const onClose = (event) => {
        event.preventDefault()
        props.closePopUp()
    }
    return (
        <div className="w-screen h-screen absolute top-0 left-0 bg-black z-50 flex items-center justify-center bg-opacity-60">
            <div className="bg-white py-7 flex flex-col w-5/12">
                <div className="mb-7 px-5">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {TranslationPopUpTitles[props.contentType] || t("form.default-title")}
                    </h3>
                </div>
                <div className="max-h-96 px-5 overflow-y-scroll">
                    {props.locales?.map((locale, index) => {
                        const inputId = `${props.contentType}-${locale}`
                        const value = translations[inputId] || ""
                        return (
                            <div key={index} className="flex justify-between items-center mb-2.5">
                                <label htmlFor={inputId} className="block text-sm font-medium text-gray-700 w-16">{locale}</label>
                                <div className="flex grow flex-col ml-5">
                                    <input
                                        maxLength="400"
                                        type="text"
                                        onChange={onChange} 
                                        value={value} 
                                        id={inputId} 
                                        name={inputId} 
                                        placeholder={`Collection ${props.contentType.charAt(0).toUpperCase() + props.contentType.slice(1)}`}
                                        className="flex-1 block grow focus:ring-black focus:border-black min-w-0 sm:text-sm border-gray-300 p-3 false"
                                    />
                                    {maxLengthAlert.includes(locale) && (
                                        <span className="text-red-600 text-xs mt-1.5">{t("form.bundle-name-max-length")}</span>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="flex mt-7 px-5">
                    <button
                        onClick={onClose}
                        className="grow justify-center border border-gray-300 shadow-sm p-3 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:mt-0 sm:col-start-1 sm:text-sm mx-1 hover:opacity-75 text-center duration-200"
                    >
                        {t('form.alert.close')}
                    </button>
                    <button
                        onClick={onSave}
                        type="submit"
                        className="grow justify-center border border-transparent shadow-sm p-3 bg-black text-base font-medium text-white hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:col-start-2 sm:text-sm mx-1 hover:opacity-75 text-center duration-200"
                    >
                        {t('form.alert.save')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CollectionForm;