import { useTranslation } from "react-i18next";
import "../../i18n";
import { useEffect, useState } from "react";
import { getBundleStatusData, getCollectionStatusData } from "@/utils/admin/fetch";

const Status = () => {
  const { t } = useTranslation("translation");
  const [bundleData, setBundleData] = useState({});
  const [collectionData, setCollectionData] = useState({});

  useEffect(() => {
    getBundleStatusData().then((res) => {
        const published = res.reduce(function (obj, v) {
          obj[v.published] = (obj[v.published] || 0) + 1;
          return obj;
        }, {});
        const inStock = res.reduce(function (obj, v) {
          obj[v.in_stock] = (obj[v.in_stock] || 0) + 1;
          return obj;
        }, {});
        const live = res.reduce(function (obj, v) {
          obj[v.is_live] = (obj[v.is_live] || 0) + 1;
          return obj;
        }, {});
        setBundleData({
            total: res.length,
            published: published[1] || 0,
            out_of_stock: inStock[0] ? inStock[0] : 0,
            live: live[1] || 0,
        })
      });
  }, []);

  useEffect(() => {
    getCollectionStatusData().then((res) => {
      const published = res.reduce(function (obj, v) {
        obj[v.published] = (obj[v.published] || 0) + 1;
        return obj;
      }, {});
      const live = res.reduce(function (obj, v) {
        obj[v.is_live] = (obj[v.is_live] || 0) + 1;
        return obj;
      }, {});
      setCollectionData({
          total: res.length,
          published: published[1] || 0,
          live: live[1] || 0,
      })
    });
  }, []);

  const stats = [
    {
      name: t('bundle-status.total'),
      stat: bundleData.total,
      color: "green",
    },
    {
      name: t('bundle-status.published'),
      info: t('bundle-status.published-info'),
      stat: bundleData.published,
      color: "green",
    },
    {
      name: t('bundle-status.out-of-stock'),
      stat: bundleData.out_of_stock,
      color: "red",
    },
    {
      name: t('bundle-status.live'),
      stat: bundleData.live,
      color: "green",
    },
    {
      name: t('collection-status.total'),
      stat: collectionData.total,
      color: "green",
    },
    {
      name: t('collection-status.published'),
      info: t('collection-status.published-info'),
      stat: collectionData.published,
      color: "green",
    },
    {
      name: t('collection-status.live'),
      stat: collectionData.live,
      color: "green",
    }
  ];

  return (
    <div className="container mx-auto py-12">
      <h3 className="text-lg font-medium leading-6 text-gray-900">
        {t('bundle-status.title')}
      </h3>
      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden bg-white shadow md:grid-cols-4 md:divide-y-0 md:divide-x">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">
              {item.name} {item.info && <span className="text-xs text-gray-400">({item.info})</span>}
            </dt>
            <dd className="mt-2 flex items-baseline justify-between md:block lg:flex">
              <span
                className={`inline-flex rounded-full bg-${item.color}-100 px-5 text-xl font-semibold leading-7 text-${item.color}-800`}
              >
                {item.stat}
              </span>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default Status;
