import { useState } from "react";
import { Link } from "react-router-dom";
import Alert from "@/components/admin/Alert";
import { deleteCollectionGroup, deleteCrossLocaleCollectionGroup, updateCollectionsOrder } from "@/utils/admin/fetch";
import { handleLogout } from "@/utils/admin/logout";
import CircleLoader from "../CircleLoader";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { useTranslation } from "react-i18next";
import "../../i18n";

export default function CollectionGroupTable({ collections, loading, multiLocale }) {
  const [alert, setAlert] = useState(null);

  const { t } = useTranslation("translation");

  const getActionLink = (action, collection) => {
    const id = multiLocale ? collection.id : `${collection.id}-${collection.view_id}`
    return (multiLocale ? "/collection-group/cross-locale" : "/collection-group") + `/${action}/${id}`;
  }

  const handleDeletion = (id, view_id) => {
    setAlert({
      title: t("table.alert.title"),
      error: false,
      shown: true,
      actions: {
        secondary: {
          callback: () => setAlert({ ...alert, shown: false }),
          title: t("table.alert.close"),
        },
        primary: {
          callback: () => {
            setAlert(null);
            const deletionResponseHandler = (data) => {
              let alertData = {
                shown: true,
                actions: {
                  primary: {
                    callback: () => setAlert({ ...alert, shown: false }),
                    title: t("table.alert.close"),
                  },
                },
              };
              if (data.status === 401) {
                handleLogout();
              } else {
                if (data.result[0]?.affectedRows || data.result?.affectedRows)
                  setAlert({
                    ...alertData,
                    actions: {
                      primary: {
                        ...alertData.actions.primary,
                        callback: () => {
                          window.location.reload();
                        },
                      },
                    },
                    title: t("table.alert.collection-group-deleted"),
                    error: false,
                  });
                else
                  setAlert({
                    ...alertData,
                    title: t("table.alert.collection-group-not-deleted"),
                    message:
                      data?.statusCode === 500
                        ? ""
                        : t("table.alert.collection-group-not-found"),
                    error: true,
                  });
              }
            }
            if (multiLocale) {
              deleteCrossLocaleCollectionGroup(id).then(deletionResponseHandler);
            } else {
              deleteCollectionGroup(id, view_id).then(deletionResponseHandler);
            }
          },
          title: "Confirm",
        },
      },
    });
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    collections = reorder(
      collections,
      result.source.index,
      result.destination.index
    );

    const collectionsSimplified = collections.map((collection, index) => (
      collection.id
    ))
    
    let res = updateCollectionsOrder(collectionsSimplified)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  return (
    <>
      <Alert
        title={alert?.title}
        message={alert?.message}
        error={alert?.error}
        shown={alert?.shown}
        actions={alert?.actions}
      />

      <div className="container mx-auto">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden ring-black ring-opacity-5">
                
                <table className="min-w-full divide-y divide-gray-300 bundle-table">
                  <thead>
                    <tr>
                      {!multiLocale && (
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          {t("table.locale")}
                        </th>
                      )}
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900 overflow-column"
                      >
                        {t("table-collection-group.image")}
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900 overflow-column"
                      >
                        {t("table-collection-group.image-style")}
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900 overflow-column"
                      >
                        {!multiLocale ? t("table-collection-group.name") : t("table-collection-group.generic-name")}
                      </th>
                      {!multiLocale && (
                        <th
                          scope="col"
                          className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900 overflow-column"
                        >
                          {t("table-collection-group.generic-name")}
                        </th>
                      )}
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("table-collection-group.bundle-associated")}
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("table.start-date")}
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("table.end-date")}
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("table.status")}
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("table.live")}
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-2 pr-3 sm:pr-6"
                      >
                        <span className="sr-only">{t("table.actions")}</span>
                      </th>
                    </tr>
                  </thead>
                    {multiLocale ? (
                      !loading ? (
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="droppable">
                            {provided => (
                              <tbody {...provided.droppableProps} ref={provided.innerRef} className="divide-y divide-gray-200 bg-white">
                                {collections.map((collection, index) => (
                                  <Draggable
                                    key={collection.id.toString()}
                                    draggableId={collection.id.toString()}
                                    index={index}
                                  >
                                    {provided => (
                                      <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-500 sm:pl-6 md:pl-2 overflow-column capitalize">
                                          <img className="max-h-[30px]" src={collection.image.url}></img>
                                        </td>
                                        <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-500 sm:pl-6 md:pl-2 overflow-column capitalize">
                                          {collection.image.style}
                                        </td>
                                        <td className="whitespace-nowrap py-3 px-2 text-sm text-gray-500 overflow-column">
                                          {collection.name}
                                        </td>
                                        <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-500 sm:pl-6 md:pl-2 overflow-column">
                                          {JSON.parse(collection.bundles).map(bundle => bundle.name).join(', ')}
                                        </td>
                                        {!multiLocale && (
                                          <td className="whitespace-nowrap py-3 pl-2 text-sm text-gray-500 overflow-column">
                                            {collection.generic_name}
                                          </td>
                                        )}
                                        <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">
                                          {collection.start_at.split("T")[0]}
                                        </td>
                                        <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">
                                          {collection.end_at.split("T")[0]}
                                        </td>
                                        <td className="whitespace-nowrap px-2 py-4 text-sm font-medium text-gray-500">
                                          {collection.published ? (
                                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                              Published
                                            </span>
                                          ) : (
                                            <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                                              Draft
                                            </span>
                                          )}
                                        </td>
                                        <td className="whitespace-nowrap px-2 py-4 text-sm font-medium text-gray-500 center">
                                          {collection.is_live === 1 ? (
                                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                              Live
                                            </span>
                                          ) : (
                                            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                              Not live
                                            </span>
                                          )}
                                        </td>
                                        <td className="relative whitespace-nowrap py-4 pl-2 pr-3 text-right text-sm sm:pr-6">
                                          <Link
                                            className="mr-3.5"
                                            to={getActionLink("edit", collection)}
                                          >
                                            <button className="text-black hover:opacity-50">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="#6b7280"
                                                strokeWidth={2}
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                                />
                                              </svg>
                                            </button>
                                          </Link>
                                          <button
                                            onClick={() =>
                                              handleDeletion(collection.id, collection.view_id)
                                            }
                                            className="text-black hover:opacity-50"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="h-6 w-6"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke="#c22014"
                                              strokeWidth={2}
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                              />
                                            </svg>
                                          </button>
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </tbody>
                            )}
                          </Droppable>
                        </DragDropContext>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="9" className="mt-2.5">
                              <CircleLoader />
                            </td>
                          </tr>
                        </tbody>
                      )
                    ) : (
                      <tbody className="divide-y divide-gray-200 bg-white">
                      {!loading ? (
                        <>
                          {collections.map((collection) => (
                            <tr key={`${collection.id}-${collection.view_id}`}>
                              <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-500 sm:pl-6 md:pl-2 overflow-column">
                                {collection.view_id}
                              </td>
                              <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-500 sm:pl-6 md:pl-2 overflow-column capitalize">
                                <img className="max-h-[30px]" src={collection.image.url}></img>
                              </td>
                              <td className="whitespace-nowrap py-3 px-2 text-sm text-gray-500 overflow-column capitalize">
                                {collection.image.style}
                              </td>
                              <td className="whitespace-nowrap py-3 px-2 text-sm text-gray-500 overflow-column">
                                {collection.name}
                              </td>
                              {!multiLocale && (
                                <td className="whitespace-nowrap py-3 pl-3 text-sm text-gray-500 overflow-column">
                                  {collection.generic_name}
                                </td>
                              )}
                              <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-500 sm:pl-6 md:pl-2 overflow-column">
                                {JSON.parse(collection.bundles).map(bundle => bundle.name).join(', ')}
                              </td>
                              <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">
                                {collection.start_at.split("T")[0]}
                              </td>
                              <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">
                                {collection.end_at.split("T")[0]}
                              </td>
                              <td className="whitespace-nowrap px-2 py-4 text-sm font-medium text-gray-500">
                                {collection.published ? (
                                  <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                    Published
                                  </span>
                                ) : (
                                  <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                                    Draft
                                  </span>
                                )}
                              </td>
                              <td className="whitespace-nowrap px-2 py-4 text-sm font-medium text-gray-500 center">
                                {collection.is_live === 1 ? (
                                  <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                    Live
                                  </span>
                                ) : (
                                  <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                    Not live
                                  </span>
                                )}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-2 pr-3 text-right text-sm sm:pr-6">
                                <Link
                                  className="mr-3.5"
                                  to={getActionLink("edit", collection)}
                                >
                                  <button className="text-black hover:opacity-50">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-6 w-6"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="#6b7280"
                                      strokeWidth={2}
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                      />
                                    </svg>
                                  </button>
                                </Link>
                                <button
                                  onClick={() =>
                                    handleDeletion(collection.id, collection.view_id)
                                  }
                                  className="text-black hover:opacity-50"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="#c22014"
                                    strokeWidth={2}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                    />
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                          <tr>
                            <td colSpan="9" className="mt-2.5">
                              <CircleLoader />
                            </td>
                          </tr>
                      )}
                      </tbody>
                    )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
