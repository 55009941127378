import { useEffect, useState } from "react";
import Table from "@/components/admin/Table";
import { getCrossLocaleBundleList } from "@/utils/admin/fetch";
import { handleLogout } from "@/utils/admin/logout";
import { Link } from "react-router-dom";
import { LOCALES } from "@/const";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const CrossLocaleBundleList = () => {
  const [bundles, setBundles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [locale, setLocale] = useState("");

  const { t } = useTranslation("translation");

  const handleChange = ({ target }) => {
    const value = target.value === "all" ? "" : target.value;
    setLocale(value);
  };

  useEffect(() => {
    getCrossLocaleBundleList({ view_id: locale }).then((res) => {
      if (res.status !== 401) {
        setLoading(true);
        setTimeout(function () {
          setBundles(res);
          setLoading(false);
        }, 250);
      } else handleLogout();
    });
  }, [locale]);

  return (
    <div className="page-content">
      <div className="container mx-auto">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              {t("multiple-locale-bundle-list.title")}
            </h1>
            <p className="mt-2 text-sm text-gray-700">{t("multiple-locale-bundle-list.intro")}</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex gap-1">
            <select
              required
              id="bundleLocale"
              name="bundleLocale"
              className={`mt-1 block w-full text-base border-gray-300 focus:outline-none focus:ring-black focus:border-black sm:text-sm p-3 w-auto pr-9`}
              value={locale === "" ? "all" : locale}
              onChange={handleChange}
            >
              <option key={-1} value={"all"}>
                {t("bundle-list.filters.all-locales")}
              </option>
              {LOCALES.map((locale, index) => (
                <option key={index} value={locale}>
                  {locale}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link to="/bundles/new">
              <button
                className="inline-flex items-center justify-center border border-transparent bg-black p-3 text-sm font-medium text-white shadow-sm hover:opacity-75 duration-200 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 sm:w-auto"
                type="button"
              >
                {t("table.add")}
              </button>
            </Link>
          </div>
        </div>
        <Table bundles={bundles} loading={loading} multiLocale/>
      </div>
    </div>
  );
};

export default CrossLocaleBundleList;
