import { LoginForm } from "@/components/admin/Login";

import { useTranslation } from 'react-i18next';
import '../../i18n';

const SignInPage = () => {
  const { t } = useTranslation('translation');
  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h1 className="mt-6 text-center text-3xl font-bold text-gray-900">{t('login.title')}</h1>
            <h2 className="mt-6 text-center text-xl font-normal text-gray-900">{t('login.subtitle')}</h2>
          </div>
          <LoginForm />
        </div>
      </div>
    </>
  )
}

export default SignInPage 