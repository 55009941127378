import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { handleLogout } from "@/utils/admin/logout";

import { useTranslation } from 'react-i18next';
import '../../i18n';

export const Nav = () => {
  const [collapsed, collapse] = useState(false);
  const [isUserLogged, setUserLogged] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem('user')
    if(user) setUserLogged(true)
  }, [])

  const { t } = useTranslation('translation');

  return (
    <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 border">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <a href="/" className="flex items-center">
          <img
            src="https://logosarchive.com/wp-content/uploads/2021/08/The-North-Face-logo.svg"
            className="mr-3 h-6 sm:h-9"
            alt="TNF Logo"
          />
        </a>
        {isUserLogged ? <>
          <button
            onClick={() => collapse(!collapsed)}
            data-collapse-toggle="mobile-menu"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-200 dark:focus:ring-gray-200"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            {collapsed ? (
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            )}
          </button>
          <div
            className={`${collapsed ? "" : "hidden"} w-full md:block md:w-auto`}
            id="mobile-menu"
          >
            <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
              <li>
                <Link to="/bundle-list" className="block py-2 pr-4 pl-3 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-black md:p-0 dark:text-gray-400 md:dark:hover:text-dark dark:hover:bg-gray-200 dark:hover:text-dark md:dark:hover:bg-transparent">
                  {t('nav.bundle-preview')}
                </Link>
              </li>
              <li>
                <Link to="/" className="block py-2 pr-4 pl-3 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-black md:p-0 dark:text-gray-400 md:dark:hover:text-dark dark:hover:bg-gray-200 dark:hover:text-dark md:dark:hover:bg-transparent">
                  {t('nav.bundle-table')}
                </Link>
              </li>
              <li>
                <Link to="/admin/bundles/new" className="block py-2 pr-4 pl-3 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-black md:p-0 dark:text-gray-400 md:dark:hover:text-dark dark:hover:bg-gray-200 dark:hover:text-dark md:dark:hover:bg-transparent">
                  {t('nav.new-bundle')}
                </Link>
              </li>
              <li>
                <a
                  onClick={handleLogout}
                  href="/login"
                  className="block py-2 pr-4 pl-3 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-black md:p-0 dark:text-gray-400 md:dark:hover:text-dark dark:hover:bg-gray-200 dark:hover:text-dark md:dark:hover:bg-transparent"
                >
                  {t('nav.logout')}
                </a>
              </li>
            </ul>
          </div>
        </> : null}
      </div>
    </nav>
  );
};
