const HREFS = {
    newCollectionGroup: '/collection-group/new',
    listCollectionGroupPerLocale: '/collection-group-list',
    listCollectionGroupPerId: '/collection-group-list/cross-locale',
    newCollection: '/collection/new',
    listCollectionPerId: '/collection-list/cross-locale',
    newBundleGroup: '/bundle-group/new',
    listBundleGroupPerLocale: '/bundle-group-list',
    listBundleGroupPerId: '/bundle-group-list/cross-locale',
    newBundle: '/bundles/new',
    listPerLocale: '/list',
    listPerId: '/list/cross-locale',
    orderListPerId: '/order-list/cross-locale',
    import: '/bundles/import'
}

export const getDashActions = (t) => [
    {
        title: t("home.add-multi-locale-bundle"),
        description: t("home.add-multi-locale-bundle-description"),
        href: HREFS.newBundle
    },
    {
        title: t("home.view-bundles-per-locale"),
        description: t("home.view-bundles-per-locale-description"),
        href: HREFS.listPerLocale
    },
    {
        title: t("home.view-all-multi-locale-bundle"),
        description: t("home.view-all-multi-locale-bundle-description"),
        href: HREFS.listPerId
    },
    {
        title: t("home.add-multi-locale-bundle-group"),
        description: t("home.add-multi-locale-bundle-group-description"),
        href: HREFS.newBundleGroup
    },
    {
        title: t("home.view-bundle-group-per-locale"),
        description: t("home.view-bundle-group-per-locale-description"),
        href: HREFS.listBundleGroupPerLocale
    },
    {
        title: t("home.view-all-multi-locale-bundle-group"),
        description: t("home.view-all-multi-locale-bundle-group-description"),
        href: HREFS.listBundleGroupPerId
    },
    {
        title: t("home.add-multi-locale-collection"),
        description: t("home.add-multi-locale-collection-description"),
        href: HREFS.newCollectionGroup
    },
    {
        title: t("home.view-collections-per-locale"),
        description: t("home.view-collections-per-locale-description"),
        href: HREFS.listCollectionGroupPerLocale
    },
    {
        title: t("home.view-all-multi-locale-collection"),
        description: t("home.view-all-multi-locale-collection-description"),
        href: HREFS.listCollectionGroupPerId
    }
]

export const getDrawerActions = (t) => [
    {
        title: t("drawer.add-multi-locale-bundle"),
        href: HREFS.newBundle
    },
    {
        title: t("drawer.view-all-multi-locale-bundle"),
        href: HREFS.listPerId
    },
    {
        title: t("drawer.view-bundles-per-locale"),
        href: HREFS.listPerLocale
    },
    // {
    //     title: t("drawer.import-file"),
    //     href: HREFS.import
    // },
    {
        title: t("drawer.add-multi-locale-bundle-group"),
        href: HREFS.newBundleGroup
    },
    {
        title: t("drawer.view-all-multi-locale-bundle-group"),
        href: HREFS.listBundleGroupPerId
    },
    {
        title: t("drawer.view-bundle-group-per-locale"),
        href: HREFS.listBundleGroupPerLocale
    },
    {
        title: t("drawer.order-all-multi-locale-bundle"),
        href: HREFS.orderListPerId
    },
    {
        title: t("drawer.add-multi-locale-collection"),
        href: HREFS.newCollection
    },
    {
        title: t("drawer.view-multi-locale-collection"),
        href: HREFS.listCollectionPerId
    },
    {
        title: t("drawer.add-multi-locale-collection-group"),
        href: HREFS.newCollectionGroup
    },
    {
        title: t("drawer.view-all-multi-locale-collection-group"),
        href: HREFS.listCollectionGroupPerId
    },
    {
        title: t("drawer.view-collection-group-per-locale"),
        href: HREFS.listCollectionGroupPerLocale
    }
]

export const encodeCSV = data => data.reduce((csv,row) => csv += row.join(';') + '\n', []);
