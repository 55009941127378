import React from 'react';
import { Outlet, Link, useLocation} from "react-router-dom";
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { getDrawerActions } from "@/utils/admin/generic";
import { ReactComponent as TNFLogo } from '@/assets/svg/TNFLogo.svg'

import { useTranslation } from "react-i18next";
import "../i18n";

import { handleLogout } from "@/utils/admin/logout";

const separators = [2, 6, 8];
const getNavigation = (t) => getDrawerActions(t)
  // add current nav item
  .map(item => {
    if (item.href === useLocation().pathname) {
      item.current = true
    }
    return item
  })

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const LayoutV2 = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [loggedUser, setLoggedUser] = useState();

  const { t } = useTranslation("translation");

  useEffect(() => {
    const user = localStorage.getItem('user')
    if (user) setLoggedUser(JSON.parse(user))
  }, [])

  return (
    <>
      <>
        <div className="min-h-full">
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 lg:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex h-10 w-10 items-center justify-center focus:outline-none"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <svg
                            stroke="white"
                            fill="white"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill="none"
                              stroke="#FFF"
                              strokeWidth="2"
                              d="M3,3 L21,21 M3,21 L21,3"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex flex-shrink-0 items-center px-4">
                      <a href="/">
                        <TNFLogo className="h-8 w-auto" />
                      </a>
                    </div>
                    <div className="mt-5 h-0 flex-1 overflow-y-auto">
                      <nav className="px-2 flex flex-col justify-between h-full">
                        <div className="space-y-1">
                          {getNavigation(t).map((item) => (
                            <Link
                              key={item.title}
                              to={item.href}
                              aria-current={item.current ? "page" : undefined}
                              className={classNames(
                                item.current
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-600 hover:text-gray-900 hover:bg-gray-50",
                                "group flex items-center px-2 py-2 text-base leading-5 font-medium"
                              )}
                            >
                              {item.title}
                            </Link>
                          ))}
                        </div>
                        <Link
                          onClick={handleLogout}
                          to="/login"
                          className="group flex items-center px-2 py-2 text-sm font-medium text-red-700 hover:bg-gray-50"
                        >
                          {t("nav.logout")}
                        </Link>
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="w-14 flex-shrink-0" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:border-r lg:border-gray-200 lg:bg-gray-100 lg:pt-5 lg:pb-4">
            <div className="flex flex-shrink-0 items-center px-6">
              <a href="/">
                <TNFLogo className="h-8 w-auto" />
              </a>
            </div>
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="mt-5 flex h-0 flex-1 flex-col overflow-y-auto pt-1">
              {/* User account dropdown */}
              <div className="relative inline-block px-3 text-left">
                <div>
                  <div className="group w-full bg-gray-100 px-3.5 py-2 text-left text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 focus:ring-offset-gray-100">
                    <span className="flex w-full items-center justify-between">
                      <span className="flex min-w-0 items-center justify-between space-x-3">
                        <span className="flex min-w-0 flex-1 flex-col">
                          <span className="truncate text-sm font-medium text-gray-900">
                            {loggedUser?.name} {loggedUser?.surname}
                          </span>
                          <span className="truncate text-sm text-gray-500">
                            {loggedUser?.email}
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {/* Navigation */}
              <nav className="mt-6 px-3 flex flex-col justify-between h-full">
                <div className="space-y-1">
                  {getNavigation(t).map((item, index, array) => (
                    <React.Fragment key={item.title}>
                      <Link
                        to={item.href}
                        aria-current={item.current ? "page" : undefined}
                        className={classNames(
                          item.current
                            ? "bg-gray-200 text-gray-900"
                            : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
                          "group flex items-center px-2 py-2 text-sm font-medium"
                        )}
                      >
                        {item.title}
                      </Link>
                      {separators.includes(index) && (
                      <hr
                        className={classNames(
                          "separator-margin border-t border-gray-300",
                          index === 6 && "separator-margin--large"
                        )}
                      />
                    )}
                    </React.Fragment>
                  ))}
                </div>
                <Link
                  onClick={handleLogout}
                  to="/login"
                  className="group flex items-center px-2 py-2 text-sm font-medium text-red-700 hover:bg-gray-50"
                >
                  {t("nav.logout")}
                </Link>
              </nav>
            </div>
          </div>
          {/* Main column */}
          <div className="flex flex-col lg:pl-64 h-screen overflow-y-auto">
            <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:hidden">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z"></path>
                </svg>
              </button>
              <div className="flex flex-1 justify-between px-4 sm:px-6 lg:px-8">
                {/* <div className="flex flex-1">
                TNF Bundle Admin
              </div> */}
              </div>
            </div>
            <Outlet />
          </div>
        </div>
      </>
    </>
  );
};

export default LayoutV2;
