import { useEffect, useState } from "react";
import MixedTable from "@/components/admin/MixedTable";
import { getCrossLocaleBundleList, getCrossLocaleBundleGroupList } from "@/utils/admin/fetch";
import { handleLogout } from "@/utils/admin/logout";
import { LOCALES } from "@/const";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const CrossLocaleBundleOrderList = () => {
  const [bundles, setBundles] = useState([]);
  const [bundleGroups, setBundleGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [locale, setLocale] = useState("");

  const { t } = useTranslation("translation");

  const handleChange = ({ target }) => {
    const value = target.value === "all" ? "" : target.value;
    setLocale(value);
  };

  const fetchBundlesAndGroups = async () => {
    setLoading(true);
    try {
      const [bundleRes, bundleGroupRes] = await Promise.all([
        getCrossLocaleBundleList({ view_id: locale, orderBy: 'cross_order' }),
        getCrossLocaleBundleGroupList({ view_id: locale, orderBy: 'cross_order' })
      ]);
      
      if (bundleRes.status !== 401 && bundleGroupRes.status !== 401) {
        setBundles(bundleRes);
        setBundleGroups(bundleGroupRes);
      } else {
        handleLogout();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBundlesAndGroups();
  }, [locale]);

  // Extracting bundle IDs from bundleGroups
  const bundleGroupIds = new Set(
    bundleGroups.flatMap(group => {
      try {
        const bundles = JSON.parse(group.bundles);
        return Array.isArray(bundles) ? bundles.map(bundle => bundle.id) : [];
      } catch (error) {
        console.error('Error parsing bundles JSON:', error);
        return [];
      }
    })
  );

  const filteredBundles = bundles.filter(bundle => 
    !bundleGroupIds.has(bundle.id) && String(bundle.discount) !== '0'
  );

  return (
    <div className="page-content">
      <div className="container mx-auto">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              {t("table.bundle-experience")}
            </h1>
            <p className="mt-2 text-sm text-gray-700">{t("table.bundle-experience-text")}</p>
            <b className="block mt-2 text-gray-500 text-sm">{t("table.bundle-experience-note")}</b>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex gap-1">
            <select
              required
              id="bundleLocale"
              name="bundleLocale"
              className={`mt-1 block w-full text-base border-gray-300 focus:outline-none focus:ring-black focus:border-black sm:text-sm p-3 w-auto pr-9`}
              value={locale === "" ? "all" : locale}
              onChange={handleChange}
            >
              <option key={-1} value={"all"}>
                {t("bundle-list.filters.all-locales")}
              </option>
              {LOCALES.map((locale, index) => (
                <option key={index} value={locale}>
                  {locale}
                </option>
              ))}
            </select>
          </div>
        </div>
        <MixedTable bundles={filteredBundles} bundleGroups={bundleGroups} loading={loading} multiLocale/>
      </div>
    </div>
  );
};

export default CrossLocaleBundleOrderList;
