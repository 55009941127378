import { Routes, Route, Navigate, HashRouter } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.css';
import Layout from '@/layouts/AdminLayout';
import ScrollToTop from '@/utils/admin/scrollToTop';
import React, { Suspense } from 'react';

/* Pages */
import Home from '@/pages/admin/Home';

import BundleForm from '@/pages/admin/bundle/BundleForm';
import BundleList from '@/pages/admin/bundle/BundleList';
import CrossLocaleBundleList from '@/pages/admin/bundle/CrossLocaleBundleList';
import BundleImport from "./pages/admin/bundle/BundleImport";

import BundleGroupForm from '@/pages/admin/bundle-group/BundleGroupForm';
import BundleGroupList from '@/pages/admin/bundle-group/BundleGroupList';
import CrossLocaleBundleGroupList from '@/pages/admin/bundle-group/CrossLocaleBundleGroupList';

import CollectionForm from '@/pages/admin/collection/CollectionForm';
import CrossLocaleCollectionList from '@/pages/admin/collection/CrossLocaleCollectionList';

import CollectionGroupForm from '@/pages/admin/collection-group/CollectionGroupForm';
import CollectionGroupList from '@/pages/admin/collection-group/CollectionGroupList';
import CrossLocaleCollectionGroupList from '@/pages/admin/collection-group/CrossLocaleCollectionGroupList';

import CrossLocaleBundleOrderList from '@/pages/admin/bundle/CrossLocaleBundleOrderList';

import Error404 from '@/pages/404';
import SignInPage from '@/pages/admin/SignInPage';

import { useTranslation } from 'react-i18next';
import './i18n';
import LayoutV2 from "./layouts/AdminLayoutV2";

function App() {
  const { t } = useTranslation('translation');
  return (
    <Suspense fallback={<Loader label={t("loading")} />}>
      <HelmetProvider context={{}}>
        <Helmet>
          <title>{t("page-title")}</title>
          <meta name="description" content="App Description" />
          <meta name="theme-color" content="#000" />
          <link
            rel="shortcut icon"
            href="https://static.thenorthface.eu/ui/www/images/favicon.hash-eb9634ef626b8b5129b4295abdeb6e2f.ico"
          />
        </Helmet>
        <div className="App">
          <HashRouter>
            <ScrollToTop />

            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<Layout />}>
                <Route path="/login" element={<SignInPage />} />
              </Route>

              <Route path="/" element={<LayoutV2 />}>
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/collection-group-list"
                  element={
                    <PrivateRoute>
                      <CollectionGroupList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/collection-group-list/cross-locale"
                  element={
                    <PrivateRoute>
                      <CrossLocaleCollectionGroupList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/collection-group/new"
                  element={
                    <PrivateRoute>
                      <CollectionGroupForm create multiLocale/>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/collection-group/edit/:key"
                  element={
                    <PrivateRoute>
                      <CollectionGroupForm edit />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/collection-group/cross-locale/edit/:key"
                  element={
                    <PrivateRoute>
                      <CollectionGroupForm edit multiLocale/>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bundle-group-list"
                  element={
                    <PrivateRoute>
                      <BundleGroupList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bundle-group-list/cross-locale"
                  element={
                    <PrivateRoute>
                      <CrossLocaleBundleGroupList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bundle-group/new"
                  element={
                    <PrivateRoute>
                      <BundleGroupForm create multiLocale/>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bundle-group/edit/:key"
                  element={
                    <PrivateRoute>
                      <BundleGroupForm edit />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bundle-group/cross-locale/edit/:key"
                  element={
                    <PrivateRoute>
                      <BundleGroupForm edit multiLocale/>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/list"
                  element={
                    <PrivateRoute>
                      <BundleList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/list/cross-locale"
                  element={
                    <PrivateRoute>
                      <CrossLocaleBundleList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bundles/new"
                  element={
                    <PrivateRoute>
                      <BundleForm create multiLocale/>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bundles/edit/:key"
                  element={
                    <PrivateRoute>
                      <BundleForm edit />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bundles/cross-locale/edit/:key"
                  element={
                    <PrivateRoute>
                      <BundleForm edit multiLocale/>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/collection-list/cross-locale"
                  element={
                    <PrivateRoute>
                      <CrossLocaleCollectionList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/collection/new"
                  element={
                    <PrivateRoute>
                      <CollectionForm create multiLocale/>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/collection/cross-locale/edit/:key"
                  element={
                    <PrivateRoute>
                      <CollectionForm edit multiLocale/>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/order-list/cross-locale"
                  element={
                    <PrivateRoute>
                      <CrossLocaleBundleOrderList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bundles/import"
                  element={
                    <PrivateRoute>
                      <BundleImport/>
                    </PrivateRoute>
                  }
                />

                <Route path="*" element={<Error404 />} />
              </Route>
            </Routes>
          </HashRouter>
        </div>
      </HelmetProvider>
    </Suspense>
  );
}

export default App;

const PrivateRoute = ({children}) => {
  const token = localStorage.getItem('accessToken');
  if(!token) return <Navigate to="/login" replace />;
  return children
}

// loading component for suspense fallback
const Loader = (loading) => (
  <div className="App">
    <div>{loading}</div>
  </div>
);