import {useState} from 'react'
import { loginUser } from '@/utils/admin/fetch';

import { useTranslation } from 'react-i18next';
import '../../i18n';
import bcrypt from 'bcryptjs'

// SALT should be created ONE TIME upon sign up
// const salt = bcrypt.genSaltSync(10)

export const LoginForm = () => {
  const [loginError, setLoginError] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault();
    const email = e.target.email.value
    const password = bcrypt.hashSync(e.target.password.value, process.env.REACT_APP_BCRYPT_SALT) 
    const response = await loginUser({
      email,
      password
    });
    if ('accessToken' in response) {
        setLoginError(false)
        localStorage.setItem('accessToken', JSON.stringify(response['accessToken']));
        localStorage.setItem('user', JSON.stringify(response['user']));
        window.location.href = "/";
    } else {
      setLoginError(true)
    }
  }

  const { t } = useTranslation('translation');

  return (
    <form onSubmit={handleSubmit} className="mt-8 space-y-6">
      <input type="hidden" name="remember" defaultValue="true" />
      <div className="rounded-md shadow-sm -space-y-px">
        <div>
          <label htmlFor="email-address" className="sr-only">
           {t('login.email')}
          </label>
          <input
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            required
            className={`appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-black focus:border-black focus:z-10 text-m ${loginError ? 'border-red-500' : null}`}
            placeholder="Email address"
          />
        </div>
        <div>
          <label htmlFor="password" className="sr-only">
          {t('login.password')}
          </label>
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
            className={`appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-black focus:border-black focus:z-10 text-m ${loginError ? 'border-red-500' : null}`}
            placeholder="Password"
          />
        </div>
      </div>
      {loginError ? <p className="text-red-500"> {t('login.password-error')}</p> : null}
      <div>
        <button
          type="submit"
          className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium text-white bg-black hover:opacity-75 duration-200 text-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black box-border"
        >
          <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
          {t('login.sign-in')}
        </button>
      </div>
    </form>
  );
};
