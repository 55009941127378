import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Alert from "@/components/admin/Alert";
import { updateOrder } from "@/utils/admin/fetch";
import CircleLoader from "../../components/CircleLoader";
import { useTranslation } from "react-i18next";
import "../../i18n";

export default function MixedTable({ bundles, bundleGroups, loading, multiLocale }) {
  const [alert, setAlert] = useState(null);
  const [combinedList, setCombinedList] = useState([]);

  const { t } = useTranslation("translation");

  useEffect(() => {
    const initialList = [
      ...bundles.map(bundle => ({ ...bundle, type: 'bundle' })),
      ...bundleGroups.map(group => ({ ...group, type: 'bundleGroup' }))
    ].sort((a, b) => a.order - b.order);

    setCombinedList(initialList);
  }, [bundles, bundleGroups]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      combinedList,
      result.source.index,
      result.destination.index
    );

    const orderedItems = items.map((item, index) => ({
      id: item.id,
      type: item.type,
      order: index + 1,
    }));

    updateOrder(orderedItems).then(response => {
      if (response.status === 200) {
        setCombinedList(items); 
      }
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const renderImages = (item) => {
    if (item.type === 'bundleGroup') {
      return (
        <img src={item.image.url} className="max-h-[30px]"/>
      );
    } else {
      try {
        const parsedProducts = JSON.parse(item.products);
        return parsedProducts.map((product, index) => (
          <img
            key={index}
            src={`https://images.thenorthface.com/is/image/TheNorthFaceEU/${product.sku}_${product.fallback ? product.fallback : product.variant}_hero`}
            alt=""
            className="inline-block w-10"
          />
        ));
      } catch (error) {
        console.error("Error parsing products JSON:", error);
        return null;
      }
    }
  };

  return (
    <>
      <Alert
        title={alert?.title}
        message={alert?.message}
        error={alert?.error}
        shown={alert?.shown}
        actions={alert?.actions}
      />

      <div className="container mx-auto">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-300 bundle-table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900 overflow-column"
                      >
                        {t("table.images")}
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900 overflow-column"
                      >
                        {t("table.element-id")}
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("table.start-date")}
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("table.end-date")}
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("table.status")}
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("table.live")}
                      </th>
                    </tr>
                  </thead>
                  {!loading ? (
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <tbody
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="divide-y divide-gray-200 bg-white"
                          >
                            {combinedList.map((item, index) => (
                              <Draggable
                                key={`${item.type}-${item.id}`}
                                draggableId={`${item.type}-${item.id}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={`${
                                      snapshot.isDragging ? "bg-gray-100" : ""
                                    }`}
                                  >
                                    <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-500 sm:pl-6 md:pl-2 overflow-column">
                                      {renderImages(item)}
                                    </td>
                                    <td className="whitespace-nowrap py-3 px-2 text-sm text-gray-500 overflow-column">
                                      {item.name}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">
                                      {item.start_at.split("T")[0]}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">
                                      {item.end_at.split("T")[0]}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-4 text-sm font-medium text-gray-500">
                                      {item.published ? (
                                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                          Published
                                        </span>
                                      ) : (
                                        <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                                          Draft
                                        </span>
                                      )}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-4 text-sm font-medium text-gray-500 center">
                                      {item.is_live === 1 ? (
                                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                          Live
                                        </span>
                                      ) : (
                                        <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                          Not live
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </DragDropContext>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="9" className="mt-2.5">
                          <CircleLoader />
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}