export const LOCALES = [
    'cs_CZ',
    'da_DK',
    'de_AT',
    'de_CH',
    'de_DE',
    'de_LU',
    'en_DK',
    'en_FI',
    'en_GB',
    'en_IE',
    'en_LU',
    'en_SE',
    'es_ES',
    'fr_BE',
    'fr_CH',
    'fr_FR',
    'it_CH',
    'it_IT',
    'nl_BE',
    'nl_NL',
    'pl_PL',
    'pt_PT',
    'sv_FI',
    'sv_SE'
]

export const ACTIVITIES = [
    "SKIING AND SNOWBOARDING",
    "HIKING",
    "MOUNTAINEERING",
    "CLIMBING",
    "TRAINING",
    "LIFESTYLE",
    "SKI TOURING",
    "TRAIL RUNNING",
    "MOUNTAIN BIKING",
    "VIA FERRATA",
    "CAMPING"
]

export const GENDERS = [
    { id: "men", i18n: "gender-men" },
    { id: "women", i18n: "gender-women" },
    { id: "kids", i18n: "gender-kids" },
    { id: "unisex", i18n: "gender-unisex" }
]